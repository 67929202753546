<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Primary Executors</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <ul>
            <li>Either:</li>
            <ul>
              <li>Add 1 to 3 people; or</li>
              <li>Select the professional executor option</li>
            </ul>
            <li>Executors must be over 18 years old</li>
          </ul>
        </div>
      </notification-box>

      <h3 class="mb-4">Selected Executors:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.primary.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one primary Executor -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.primary"
              :key="item"
              class="mb-2"
              :id="item"
              :editable="{ address: true }"
              :type="item === 'ausUnity' ? 'professional' : 'person'"
              @remove="removePrimaryExecutor(index)"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 pt-1"
                >
                  <span
                    v-if="!entity.address"
                    class="font-italic"
                    >- Address Required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add an Executor:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected || isProfessionalSelected"
        />
        <btn-add-large
          label="Add professional Executor"
          subtitle="Australian Unity"
          :img="require('@/v2/assets/images/logo-aus-unity-small.png')"
          class="mb-1"
          @click="openDrawerProfessional = true"
          @clickDisabled="disabledMessage()"
          :disabled="
            isMaximumSelected ||
            isProfessionalSelected ||
            isProfessionalDisabled
          "
        />
      </div>
      <v-input
        :error-messages="
          showErrors.primary && errorMessages.primary
            ? errorMessages.primary
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add Executor"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addPrimaryExecutor"
        :exclude="excludePeople"
        :editable="{ address: true, name: false }"
        :mandatory="['address']"
      />
    </drawer>

    <drawer
      :open.sync="openDrawerProfessional"
      title="Add Executor"
      :width="600"
    >
      <aus-unity-accept-terms
        v-if="openDrawerProfessional"
        @accept="acceptProfessionalTerms"
        @close="openDrawerProfessional = false"
      />
    </drawer>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'
import { mapGetters } from 'vuex'

import AusUnityAcceptTerms from '../../../components/form/AusUnityAcceptTerms'
import BtnAddLarge from '../../../components/ui/BtnAddLarge'
import NotificationBox from '../../../components/ui/NotificationBox'
import Drawer from '../../../components/wrapper/Drawer'
import DrawerPersonSelect from '../../../components/wrapper/DrawerPersonSelect'
import ItemPerson from '../../../components/wrapper/ItemPerson'

export default {
  name: 'WillFormExecutorsPrimary',
  components: {
    AusUnityAcceptTerms,
    ItemPerson,
    DrawerPersonSelect,
    Drawer,
    BtnAddLarge,
    NotificationBox,
  },
  props: {
    closeLabel: {
      default: 'close',
    },
    submitLabel: {
      default: 'Save',
    },
  },
  data: () => ({
    local: null,
    showErrors: {
      primary: false,
    },
    processing: false,
    openDrawerPerson: false,
    openDrawerProfessional: false,
    acceptTermsName: null,
  }),
  mounted() {
    this.local = cloneDeep(this.will_executors.value)
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.processing = false
          this.next()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      const proAdded =
        this.isProfessionalSelected &&
        !this.will_executors.value.primary.includes('ausUnity')
      await this.$store.dispatch('account/save_user_data', {
        executors: this.localFormatted,
      })
      await this.$store.dispatch('account/update_will_progress', ['executors'])
      if (proAdded) {
        await this.$store.dispatch(
          'account/save_professional_executor_acceptance',
          {
            name: this.acceptTermsName,
            company: 'ausUnity',
          }
        )
      }
    },
    close() {
      this.$emit('close')
    },
    next() {
      this.$emit('next')
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    addPrimaryExecutor(id) {
      this.local.primary.push(id)
      this.openDrawerPerson = false
      this.openDrawerProfessional = false
    },
    removePrimaryExecutor(index) {
      this.$delete(this.local.primary, index)
    },
    disabledMessage() {
      if (this.isMaximumSelected) {
        this.$store.commit('app/SET_MESSAGE', {
          type: null,
          text: 'Maximum of 3 primary Executors',
        })
        return
      }

      if (this.isProfessionalSelected) {
        this.$store.commit('app/SET_MESSAGE', {
          type: null,
          text: 'Professional Executor already selected',
        })
        return
      }

      if (this.isProfessionalDisabled) {
        this.$store.commit('app/SET_MESSAGE', {
          type: null,
          text: 'Remove executors to select professional option',
        })
        return
      }
    },
    acceptProfessionalTerms(name) {
      this.acceptTermsName = name
      this.addPrimaryExecutor('ausUnity')
    },
  },
  computed: {
    ...mapGetters('account', ['will_executors', 'entities_people']),
    excludePeople() {
      return [
        ...this.localFormatted.primary,
        ...this.entities_people
          .filter((x) => x.guardians && x.guardians.length)
          .map((x) => x.id),
      ]
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })

      if (!this.localFormatted.primary.length) {
        msgs.primary = 'Select at least one Executor'
      }

      if (this.localFormatted.primary.length > 3) {
        msgs.primary = 'Maximum of 3 primary Executors'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.will_executors.value)
    },
    isMaximumSelected() {
      return this.localFormatted.primary.length >= 3
    },
    isProfessionalSelected() {
      return this.localFormatted.primary.includes('ausUnity')
    },
    isProfessionalDisabled() {
      return (
        this.localFormatted.primary.length > 0 &&
        !this.localFormatted.primary.includes('ausUnity')
      )
    },
    localFormatted() {
      return {
        primary: this.local.primary || [],
        backup: this.local.primary.includes('ausUnity')
          ? []
          : this.local.backup.filter((v) => {
              return !this.local.primary.includes(v)
            }),
      }
    },
  },
}
</script>

<style lang="scss"></style>
