<template>
  <div class="pa-4">
    <div class="text-center">
      <img
        src="https://media.graphassets.com/gYr4IJUQfiKFT4PCZHrO"
        className="d-inline-block my-4"
      />
      <notification-box
        class="my-4"
        :flat="true"
      >
        <p class="font-weight-bold">
          Australian Unity is one of Australia's oldest and most trusted
          companies.
        </p>
        <div>
          To add Australian Unity Trustees Limited as an Executor of your Will,
          read the Terms & Conditions and type your full name below to accept.
        </div>
      </notification-box>
    </div>

    <div class="px-4">
      <div class="w-label mb-2">Terms & Conditions</div>
      <div class="termAndContitions mb-4">
        <ol>
          <li class="mb-2">
            When you appoint a professional Executor and/or trustee, there are
            fees that are paid if they do end up acting. If Australian Unity
            Trustees Limited (ACN 162 061 556) (AUTL) does act as either your
            Executor or trustee or both, AUTL will charge for the work it does.
          </li>
          <li class="mb-2">
            The fees and charges, including any fees that might apply from other
            related entities within the Australian Unity Group, are found
            <a
              href="https://www.australianunity.com.au/wealth/-/media/RebrandWealth/Trustees-And-Advice/Documents/fee-schedule.pdf"
              target="_blank"
              >here</a
            >
            (FCS) and AUTL’s Financial Services Guide is found
            <a
              href="https://www.australianunity.com.au/wealth/-/media/RebrandWealth/Trustees-And-Advice/Documents/FSG/AUTTrusteeFSG.pdf"
              target="_blank"
              >here</a
            >
            (FSG).
          </li>
          <li>
            By writing my name below, I acknowledge that I have read the FCS and
            the FSG and I consent to AUTL charging the fees and charges that
            apply as at the date of my death if they do act as my Executor
            and/or trustee.
          </li>
        </ol>
      </div>
      <div class="text-label mb-2">Add as Executor:</div>
      <div class="mb-4">
        I have read, understood and accept the Australian Unity Trustees Limited
        Executor Terms and Conditions (agreement).
      </div>

      <div class="mb-4">
        Type your full name, <strong>{{ user_name.displayFull }}</strong
        >, below to accept:
      </div>
      <v-text-field
        v-bind="textField"
        v-model="local.name"
        placeholder="Type your full name here"
        :error-messages="
          showErrors.name && errorMessages.name ? errorMessages.name : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          text
          @click="close"
          >Cancel</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >Save</v-btn
        >
      </div>

      <div></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import NotificationBox from '../ui/NotificationBox'

export default {
  name: 'AusUnityAcceptTerms',
  components: { NotificationBox },
  props: {
    isDisabled: { default: false },
  },
  data: () => ({
    local: {
      name: '',
    },
    showErrors: {
      name: false,
    },
    processing: false,
  }),
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        this.$emit('accept', this.localFormatted.name)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    close() {
      this.$emit('close')
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
  },
  computed: {
    ...mapGetters('account', ['user_name']),
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        name: null,
      }

      if (
        this.localFormatted.name.replace(/\s+/gm, '').toLowerCase() !==
        this.user_name.displayFull.replace(/\s+/gm, '').toLowerCase()
      ) {
        msgs.name = `Type your name (${this.user_name.displayFull}) to accept`
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    localFormatted() {
      return {
        name: this.local.name.trim().replace(/\s\s+/gm, ' '),
      }
    },
  },
}
</script>
