<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Backup Executors</h2>
      <p>
        In case a primary Executor predeceases you or is otherwise unable to
        act, a backup Executor is a person appointed under the Will to
        administer your estate after your death. We recommend appointing backup
        Executors.
      </p>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <ul>
            <li>Backup Executors are optional</li>
            <li>Either:</li>
            <ul>
              <li>Choose up to 3 backup Executors; or</li>
              <li>Select the professional Executor option</li>
            </ul>
            <li>Nominate backup Executors in order of priority</li>
            <li>Backup Executors must be over 18 years old</li>
            <li>An Executor can't be both a primary and a backup</li>
          </ul>
        </div>
      </notification-box>

      <h3 class="mb-4">Selected Backups:</h3>
      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.backup.length"
          class="text-no-result"
          key="empty"
        >
          - No backups selected -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <v-list-item
              v-for="(item, index) in localFormatted.backup"
              :key="item"
              class="pa-0 mb-2"
            >
              <v-list-item-content class="pa-0">
                <item-person
                  style="width: 100%"
                  :rank="index + 1"
                  :id="item"
                  :type="item === 'ausUnity' ? 'professional' : 'person'"
                  :editable="{ address: true }"
                  @remove="removeBackupExecutor(index)"
                >
                  <template v-slot:details="{ entity }">
                    <div
                      v-if="entity.type === 'person'"
                      class="text-sm text-cutoff opacity-70 pt-1"
                    >
                      <span
                        v-if="!entity.address"
                        class="font-italic"
                        >Address Required</span
                      >
                      <span v-else>
                        {{ entity.address }}
                      </span>
                    </div>
                  </template>
                </item-person>
              </v-list-item-content>
              <v-list-item-action class="ml-1 my-0">
                <v-btn
                  @click="index > 0 ? moveUp(index) : null"
                  :color="index > 0 ? 'black' : 'grey lighten-2'"
                  :style="index === 0 ? 'cursor: default;' : ''"
                  icon
                  tile
                  ><v-icon>mdi-chevron-up</v-icon></v-btn
                >
                <v-btn
                  icon
                  tile
                  @click="
                    localFormatted.backup.length - index > 1
                      ? moveDown(index)
                      : null
                  "
                  :color="
                    localFormatted.backup.length - index > 1
                      ? 'black'
                      : 'grey lighten-2'
                  "
                  :style="
                    localFormatted.backup.length - index > 1
                      ? ''
                      : 'cursor: default;'
                  "
                  ><v-icon>mdi-chevron-down</v-icon></v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add a backup:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected || isProfessionalSelected"
        />
        <template v-if="!isProfessionalPrimarySelected">
          <btn-add-large
            label="Add professional Executor"
            subtitle="Australian Unity"
            :img="require('@/v2/assets/images/logo-aus-unity-small.png')"
            class="mb-1"
            @click="openDrawerProfessional = true"
            @clickDisabled="disabledMessage()"
            :disabled="
              isMaximumSelected ||
              isProfessionalSelected ||
              isProfessionalDisabled
            "
          />
        </template>
      </div>
      <v-input
        :error-messages="
          showErrors.backup && errorMessages.backup
            ? errorMessages.backup
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >Save</v-btn
        >
      </div>
    </div>

    <drawer
      :open.sync="openDrawerPerson"
      title="Add Backup Executor"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addBackupExecutor"
        :exclude="excludePeople"
        :editable="{ address: true, name: false }"
        :mandatory="['address']"
      />
    </drawer>

    <drawer
      :open.sync="openDrawerProfessional"
      title="Add Executor"
      :width="600"
    >
      <aus-unity-accept-terms
        v-if="openDrawerProfessional"
        @accept="acceptProfessionalTerms"
        @close="openDrawerProfessional = false"
      />
    </drawer>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'
import { mapGetters } from 'vuex'

import AusUnityAcceptTerms from '../../../components/form/AusUnityAcceptTerms'
import BtnAddLarge from '../../../components/ui/BtnAddLarge'
import NotificationBox from '../../../components/ui/NotificationBox'
import Drawer from '../../../components/wrapper/Drawer'
import DrawerPersonSelect from '../../../components/wrapper/DrawerPersonSelect'
import ItemPerson from '../../../components/wrapper/ItemPerson'

export default {
  name: 'WillFormExecutorsBackup',
  components: {
    AusUnityAcceptTerms,
    ItemPerson,
    DrawerPersonSelect,
    Drawer,
    BtnAddLarge,
    NotificationBox,
  },
  props: {
    closeLabel: {
      default: 'close',
    },
  },
  data: () => ({
    local: null,
    showErrors: {
      backup: false,
    },
    processing: false,
    openDrawerPerson: false,
    openDrawerProfessional: false,
    acceptTermsName: null,
  }),
  mounted() {
    this.local = cloneDeep(this.will_executors.value)
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.processing = false
          this.next()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      const proAdded =
        this.isProfessionalSelected &&
        !this.will_executors.value.backup.includes('ausUnity')
      await this.$store.dispatch('account/save_user_data', {
        executors: this.localFormatted,
      })
      if (proAdded) {
        await this.$store.dispatch(
          'account/save_professional_executor_acceptance',
          {
            name: this.acceptTermsName,
            company: 'ausUnity',
          }
        )
      }
    },
    close() {
      this.$emit('close')
    },
    next() {
      this.$emit('next')
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    addBackupExecutor(id) {
      this.local.backup.push(id)
      this.openDrawerPerson = false
      this.openDrawerProfessional = false
    },
    removeBackupExecutor(index) {
      this.$delete(this.local.backup, index)
    },
    disabledMessage() {
      if (this.isMaximumSelected) {
        this.$store.commit('app/SET_MESSAGE', {
          type: null,
          text: 'Maximum of 3 backup Executors',
        })
        return
      }

      if (this.isProfessionalSelected) {
        this.$store.commit('app/SET_MESSAGE', {
          type: null,
          text: 'Professional Executor already selected',
        })
        return
      }

      if (this.isProfessionalDisabled) {
        this.$store.commit('app/SET_MESSAGE', {
          type: null,
          text: 'Remove executors to select professional option',
        })
        return
      }
    },
    acceptProfessionalTerms(name) {
      this.acceptTermsName = name
      this.addBackupExecutor('ausUnity')
    },
    moveUp(index) {
      this.moveBackup(index, index - 1)
    },
    moveDown(index) {
      this.moveBackup(index, index + 1)
    },
    moveBackup(from, to) {
      this.local.backup.splice(to, 0, this.local.backup.splice(from, 1)[0])
    },
  },
  computed: {
    ...mapGetters('account', ['will_executors', 'entities_people']),
    excludePeople() {
      return [
        ...this.localFormatted.primary,
        ...this.localFormatted.backup,
        ...this.entities_people
          .filter((x) => x.guardians && x.guardians.length)
          .map((x) => x.id),
      ]
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })

      if (this.localFormatted.backup.length > 3) {
        msgs.backup = 'Maximum of 3 backup Executors'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.will_executors.value)
    },
    isMaximumSelected() {
      return this.localFormatted.backup.length >= 3
    },
    isProfessionalSelected() {
      return this.localFormatted.backup.includes('ausUnity')
    },
    isProfessionalPrimarySelected() {
      return this.localFormatted.primary.includes('ausUnity')
    },
    isProfessionalDisabled() {
      return (
        this.localFormatted.backup.length > 0 &&
        !this.localFormatted.backup.includes('ausUnity')
      )
    },
    localFormatted() {
      return {
        primary: this.local.primary || [],
        backup: this.local.backup.filter((v) => {
          return !this.local.primary.includes(v)
        }),
      }
    },
  },
}
</script>

<style lang="scss"></style>
